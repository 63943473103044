import React, { useState } from 'react'
import { useRouter } from 'next/router'
import styles from '../styles/Home.module.css'
import { checksum, scramble, unscramble } from '../util'
import getConfig from 'next/config'
const { API_BASE_URL } = getConfig().publicRuntimeConfig
// const icons = require('../inline-icons.json')

export default function TokenSearchBar({ isMainPage }) {
    const router = useRouter()
    const [keyword, setKeyword] = useState()
    const [results, setResults] = useState([])
    const [showResults, setShowResults] = useState(false)
    const [debounce, setDebounce] = useState()
  
    const select = (network, addr) => {
      if (router.pathname.startsWith('/token/')) {
        const realId = router.query.id?.length == 60 ? '0x' + unscramble(router.query.id) : router.query.id;
        window.open(`/token/${network.toLowerCase()}/${scramble(addr)}`, "_self")
      }
      else
        window.open(`/token/${network.toLowerCase()}/${scramble(addr)}`, "_self")
    }
  
    const selected = (results, keyword) => {
      if (results) {
        for (const r of results) {
          if (r.addr == keyword) {
            select(r.network, r.addr)
            return true
          }
        }
      }
  
      return false
    }
  
    React.useEffect(() => {
      //if (selected(results, keyword))
      //  return;
  
      if (debounce)
        clearTimeout(debounce)
  
      if (typeof keyword !== 'undefined' && keyword.length >= 3) {
        setDebounce(setTimeout(async () => {
          const res = await fetch(`${API_BASE_URL}/tokens?keyword=${keyword}&c=${checksum(keyword)}`)
	  if (!res.ok) // probably 503 status (too many requests)
	    return 

          const tokens = await res.json()
          //if (selected(tokens, keyword))
          //  return;
          setResults(tokens)
          setShowResults(true)
        }, 500))
      }
      else {
        setResults([])
        setShowResults(false)
      }
  
      return
    }, [keyword])

    return (
        <div className={styles.searchBar}>
            {/* <img className={styles.searchIcon} src={icons.search} /> */}
            <input 
                autoComplete='off' 
                className={styles.search_Input} 
                placeholder="Search tokens by name or address" 
                type="search" 
                maxLength="42"
                onInput={(e) => true}
            />
            <input 
                autoComplete='off' 
                className={styles.searchInput} 
                placeholder="Search tokens by name or address" 
                type="search" 
                maxLength="42"
                onInput={(e) => setKeyword(e.target.value.toLowerCase())}
            />
            {showResults &&
                <>
                    {results && results.length > 0
                    ? <div className={styles.searchResults} style={isMainPage ? {maxHeight: '15em'} : {}}>
                        {results.map((r, index) => {
                            let label = (r.name || 'Unknown') + (r.symbol ? ` (${r.symbol.toUpperCase()})` : '')
                            return (
                            <div 
                                key={index} 
                                className={styles.searchResult}
                                onClick={() => select(r.network, r.addr)}
                            >
                                <div className={styles.mono}><strong>{r.network}:{r.addr}</strong></div>
                                <div>
                                {r.image_url && <img src={r.image_url} className={styles.icon} style={{verticalAlign:'middle'}} />}
                                {' '}
                                <span style={{verticalAlign:'middle'}}>{label}</span>
                                </div>
                            </div>
                            )
                        })}
                        </div>
                    : <div className={styles.searchResults}>
                        <div className={styles.searchResultNull}>
                            No results
                        </div>
                        </div>
                    }
                </>
            }
        </div>
    )
}
