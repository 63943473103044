var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"l3qqxIiMk4SbVI-elkEga"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs"
import getConfig from 'next/config'
const { SENTRY_DSN } = getConfig().publicRuntimeConfig

if (SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
    })
}