const datetimeString = (time) => {
  if (!time)
    return ''
  let s = new Date(time*1).toUTCString("en-US") // ensure time is int, fixes "Invalid Date" bug
  return s.substring(s.indexOf(', ') + 2)
}

function timeDiffString(timeStart, timeEnd, longFormat, daysPlusHours, isRelative) {
  if (!timeStart)
    timeStart = Date.now()
  if (!timeEnd)
    timeEnd = Date.now()
  const diff = Math.abs(timeEnd - timeStart)
  const secDiff = Math.floor(diff / 1000)
  const minDiff = Math.floor(secDiff / 60)
  const hourDiff = Math.floor(minDiff / 60)
  const dayDiff = Math.floor(hourDiff / 24)

  let relativeSymbol = ''
  if (isRelative && diff >= 1000)
    relativeSymbol = timeStart < timeEnd ? '-' : '+'

  if (dayDiff > 0) {
    const hours = daysPlusHours && hourDiff > 0 ? (hourDiff % 24) + (longFormat ? ' hour' + (hourDiff != 1 ? 's' : '') : 'h') : ''
    return relativeSymbol + dayDiff.toLocaleString() + (longFormat ? ' day' + (dayDiff != 1 ? 's' : '') : 'd') + (hours ? ' ' + hours : '')
  }
  if (hourDiff > 0)
    return relativeSymbol + hourDiff + (longFormat ? ' hour' + (hourDiff != 1 ? 's' : '') : 'h')
  if (minDiff > 0)
    return relativeSymbol + minDiff + (longFormat ? ' min' : 'm')
  if (secDiff > 0)
    return relativeSymbol + secDiff + (longFormat ? ' sec' : 's')

  return 'Now'
}

// FIXME move into config file
function scanUrl(network) {
  if (network === 'BSC') 
    return 'https://bscscan.com'
  if (network === 'POLY')
    return 'https://polygonscan.com'
  if (network === 'FTM')
    return 'https://ftmscan.com'
  if (network === 'OPT')
    return 'https://optimistic.etherscan.io'
  if (network === 'HARMONY')
    return 'https://explorer.harmony.one'
  if (network === 'ETH')
    return 'https://etherscan.io'
  if (network === 'KCC')
    return 'https://explorer.kcc.io'
  if (network === 'XDAI')
    return 'https://blockscout.com'
  if (network === 'ARB')
    return 'https://arbiscan.io'
  if (network === 'AVAX')
    return 'https://snowtrace.io' //'https://avascan.info/blockchain/c/address'
  if (network === 'CRONOS')
    return 'https://cronos.crypto.org/explorer'
  if (network === 'OASIS')
    return 'https://explorer.emerald.oasis.dev'
  if (network === 'BASE')
    return 'https://basescan.org'
//   if (network === 'PULSE')
//     return 'https://otter.pulsechain.com'
  if (network === 'BLAST')
    return 'https://blastscan.io'

  throw(`Unknown network: ${network}`)
}

// FIXME move into config file
function scanName(network) {
  if (network === 'BSC') 
    return 'BscScan'
  if (network === 'POLY')
    return 'PolygonScan'
  if (network === 'FTM')
    return 'FTMScan'
  if (network === 'OPT')
    return 'OptimisticEtherScan'
  if (network === 'HARMONY')
    return 'Harmony Explorer'
  if (network === 'ETH')
    return 'Etherscan'
  if (network === 'KCC')
    return 'KCC Explorer'
  if (network === 'XDAI')
    return 'Blockscout'
  if (network === 'ARB')
    return 'Arbiscan'
  if (network === 'AVAX')
    return 'SnowTrace'
  if (network === 'CRONOS')
    return 'Cronos Explorer'
  if (network === 'OASIS')
    return 'Oasis Emerald Explorer'
if (network === 'BASE')
    return 'BaseScan'
//   if (network === 'PULSE')
//     return 'PulseChain Explorer'
  if (network === 'BLAST')
    return 'BlastScan'

  throw(`Unknown network: ${network}`)
}

// FIXME move into config file
function networkCurrency(network) {
  if (network === 'BSC') 
    return 'BNB'
  if (network === 'POLY')
    return 'MATIC'
  if (network === 'FTM')
    return 'FTM'
  // if (network === 'OPT')
  //   return ''
  // if (network === 'HARMONY')
  //   return ''
  if (network === 'ETH')
    return 'ETH'
  if (network === 'KCC')
    return 'KCS'
  if (network === 'XDAI')
    return 'XDAI'
  if (network === 'ARB')
    return 'ETH'  
  if (network === 'AVAX')
    return 'AVAX'
  if (network === 'CRONOS')
    return 'CRO'
  if (network === 'OASIS')
    return 'ROSE'
  if (network === 'BASE')
    return 'ETH'
//   if (network === 'PULSECHAIN')
//     return 'PLS'
  if (network === 'BLAST')
    return 'ETH'

  throw(`Unknown network: ${network}`)
}

function isValidAddress(addr) {
    return !!addr && (typeof addr === 'string' || addr instanceof String) && addr.startsWith('0x') && addr.length == 42
}

function precise(x) {
  if (x == 0)
    return '0'
  if (x < 1)
    return '<1'
  return Math.trunc(x*10)/10
}

function randInt(max) {
  return Math.floor(Math.random() * max)
}

function scramble(s) {
  const FILLER_CHARS = ['c','d','e','f','g','i','o','l','p','m','u','3','4','5','8']
  const CHAR_MAP = {
    '0': 'x',
    '1': '6',
    '2': 'n',
    '3': '0',
    '4': 's',
    '5': '1',
    '6': 'k',
    '7': 'q',
    '8': 'v',
    '9': 'h',
    'a': '9',
    'b': 'j',
    'c': 'b',
    'd': '2',
    'e': 'a',
    'f': 'z',
    'x': '7'
  }
  
  s = s.substring(2) // remove leading 0x
  let s2 = ''
  for (let i = 0; i < s.length; i++) {
    const c = s.charAt(i)
    if (c in CHAR_MAP)
      s2 += CHAR_MAP[c]
    else
      s2 += c
  }
  while (s2.length < 60) {
    const randChar = FILLER_CHARS[randInt(FILLER_CHARS.length)]
    const randPos = randInt(s2.length)
    s2 = s2.substring(0, randPos) + randChar + s2.substring(randPos)
  }

  return s2
}

function unscramble(s) {
  const CHAR_MAP = {
    'x': '0',
    '6': '1',
    'n': '2',
    '0': '3',
    's': '4',
    '1': '5',
    'k': '6',
    'q': '7',
    'v': '8',
    'h': '9',
    '9': 'a',
    'j': 'b',
    'b': 'c',
    '2': 'd',
    'a': 'e',
    'z': 'f',
    '7': 'x',
  }
  let s2 = ''
  for (let i = 0; i < s.length; i++) {
    const c = s.charAt(i)
    if (c in CHAR_MAP)
      s2 += CHAR_MAP[c]
  }

  return s2
}

function shortAddr(addr) {
  if (!addr || addr.length < 42)
    return ''
  return addr.substr(0,6) + '...' + addr.substr(-4)
}

//FIXME move into chains.js
const NETWORK_NAMES = ['BSC', 'Ethereum', 'Polygon', 'Fantom', 'Arbitrum', 'Optimism', 'xDai', 'Harmony', 'KCC', 'Avalanche', 'Cronos', 'Oasis', 'Base', 'Blast' ]
const NETWORK_IDS = ['BSC', 'ETH', 'POLY', 'FTM', 'ARB', 'OPT', 'XDAI', 'HARMONY', 'KCC', 'AVAX', 'CRONOS', 'OASIS', 'BASE', 'BLAST']
const CHAIN_IDS = ['56', '1', '137', '250', '42161', '10', '100', '1666600000', '321', '43114', '25', '42262', '8453', '81457']

function checksum(str) {
  let sum = 0
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i)
  }
  return sum
}

function gaPageView(req) {
  if (!process.env.GA_TRACKING_ID)
    return

  // Disable for now, not working properly
  //const visitor = ua(process.env.GA_TRACKING_ID)// req.cookies['_ga'])
  //visitor.pageview(req.url).send()
}

module.exports = { 
  datetimeString,
  timeDiffString, 
  scanUrl,
  scanName,
  networkCurrency,
  shortAddr,
  NETWORK_NAMES,
  NETWORK_IDS,
  CHAIN_IDS,
  isValidAddress,
  precise,
  scramble,
  unscramble,
  checksum,
  gaPageView,
}
