import React from "react";
import { useRouter } from "next/router";
import "../styles/globals.css";
import styles from "../styles/Home.module.css";
import Head from "next/head";
import cookie from "cookie";
import TokenSearchBar from "../components/TokenSearchBar";
import { ProfileProvider } from "../contexts/profile";
import getConfig from "next/config";
const { GA_TRACKING_ID, PROFILE_COOKIE } = getConfig().publicRuntimeConfig;
// const icons = require('../inline-icons.json')

const Header = ({ isMainPage }) => {
    return (
        <div>
            {isMainPage && (
                <div className={styles.announcement}>
                    <a className={styles.link3} href="https://tokensniffer.com/TokenSnifferAPI">
                        TokenSniffer API is now available. <span style={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}>Learn more</span>>
                    </a>
                </div>
            )}
            <div className={styles.header}>
                <a className={styles.logo} href="/">
                    <img alt="logo.svg" src="/logo2.svg" />
                    <div className={styles.headerTitle}>Token Sniffer</div>
                </a>
                {!isMainPage && <TokenSearchBar />}
            </div>
        </div>
    );
};
function MyApp({ Component, pageProps, profile }) {
    const router = useRouter();
    const isMainPage = router.pathname === "/";

    return (
        <div>
            <Head>
                <title>Token Sniffer</title>
                <meta
                    name="description"
                    content="Token Sniffer is a smart contract scam scanner monitoring over two million tokens and 250,000 scams. Scan any fungible token on Ethereum, Polygon, BNB Chain, or other blockchain to better understand the risks."
                />
                <link rel="icon" href="/logo2-solid.png" />

                {/* Import fonts -- other fonts are imported via CSS, see styles/fonts/ */}
                <link
                    href="https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
                    rel="stylesheet"
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
                    rel="stylesheet"
                />

                {/* Google Analytics (from https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_document.js) */}
                <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });`,
                    }}
                />
            </Head>

            <main className={styles.main}>
                <Header isMainPage={isMainPage} {...pageProps} />
                <ProfileProvider profile={profile}>
                    <Component {...pageProps} />
                </ProfileProvider>
            </main>

            <footer>
                <div className={styles.infoSection}>
                    <div className={styles.grid} style={{ justifyContent: "space-evenly" }}>
                        <div>
                            {/* <object data="solidus.svg" type="image/svg+xml" className={styles.solidusLogo}></object> */}
                            <img className={styles.solidusLogo} alt="solidus.svg" src="/solidus.svg" />
                        </div>
                        <div className={styles.infoText}>
                            Token Sniffer smart contract scam scanner and its technology are now integrated into Solidus Labs’ Web3 AML solutions.
                            Recently cited by the U.S. Department of the Treasury and in Senate Banking Committee testimonies, Token Sniffer is an
                            established authority on smart contract scams, and as such, will be kept publicly available as part of Solidus’ commitment
                            to the future and prosperity of the DeFi ecosystem. To learn more, please visit:{" "}
                            <a href="https://soliduslabs.com/" _target="_blank">
                                https://soliduslabs.com
                            </a>
                            <br />
                            <br />
                            <div className={styles.box} style={{ justifyContent: "space-between", paddingTop: "1em" }}>
                                <a
                                    className={styles.ctaButton}
                                    style={{ margin: "0 2em 2em 0" }}
                                    href="http://www.soliduslabs.com/solutions/threat-intelligence"
                                >
                                    Learn more about Solidus' Web3 AML solutions
                                </a>
                                <div>
                                    <a className={styles.link2} style={{ margin: "0 2em 2em 0" }} href="https://tokensniffer.com/contact">
                                        <span className="note">Contact Us</span>
                                    </a>
                                    <a
                                        className={styles.link2}
                                        style={{ margin: "0 2em 2em 0" }}
                                        href="https://billing.stripe.com/p/login/bIY4hUajh7Kz0w0144"
                                    >
                                        <span className="note">Billing</span>
                                    </a>
                                    <a className={styles.link2} style={{ margin: "0 2em 2em 0" }} href="https://tokensniffer.com/docs/terms">
                                        <span className="note">Terms of Use</span>
                                    </a>
                                    <a className={styles.link2} style={{ margin: "0 2em 2em 0" }} href="https://tokensniffer.com/docs/fulfillment">
                                        <span className="note">Fullfillment Policy</span>
                                    </a>
                                    <a
                                        className={styles.link2}
                                        style={{ margin: "0 2em 2em 0" }}
                                        href="https://www.soliduslabs.com/legal/privacy-policy"
                                    >
                                        <span className="note">Privacy policy</span>
                                    </a>
                                    <a
                                        className={styles.link2}
                                        style={{ margin: "0 2em 2em 0" }}
                                        href="https://www.soliduslabs.com/legal/cookie-policy"
                                    >
                                        Cookies policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className={styles.copyright}>
                        <img src="/twitter.svg" className={styles.icon} />{" "}
                        <a href="https://twitter.com/Token_Sniffer" target="_blank">
                            Follow
                        </a>
                        <span style={{ paddingLeft: "1em", paddingRight: "1em", color: "lightgray" }}>{" | "}</span>
                        {"© Copyright Solidus Labs "}
                        {new Date().getFullYear()}
                    </div>
                </div>
            </footer>
        </div>
    );
}

MyApp.getInitialProps = async ({ Component, ctx }) => {
    const cookies = cookie.parse(ctx.req?.headers?.cookie || "");

    let profile;
    if (cookies && cookies[PROFILE_COOKIE]) profile = JSON.parse(cookies[PROFILE_COOKIE]);
    //console.log('profile', profile)

    return {
        profile,
        pageProps: Component.getInitialProps ? await Component.getInitialProps(ctx) : {},
    };
};

// From https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_document.js
export function reportWebVitals({ id, name, label, value }) {
    if (typeof window !== "undefined") {
        window.gtag("event", name, {
            event_category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
            value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
            event_label: id, // id unique to current page load
            non_interaction: true, // avoids affecting bounce rate.
        });
    }
}

export default MyApp;
