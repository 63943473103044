import React from 'react'

const ProfileContext = React.createContext()
ProfileContext.displayName = 'Profile'

function useProfile() {
  const context = React.useContext(ProfileContext)
  if (!context) {
    throw new Error(`useProfile must be used within a ProfileProvider`)
  }
  return context
}

function ProfileProvider(props) {
  const [profile, setProfile] = React.useState(props.profile);
  const value = React.useMemo(() => [profile, setProfile], [profile])
  return <ProfileContext.Provider value={value} {...props} />
}

export { ProfileProvider, useProfile };